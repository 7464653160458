<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form @submit.prevent="handleSubmit(handleCreateItem)" @reset.prevent="resetForm(resetForm)">
      <validation-provider #default="validationContext" name=" " rules="required">
        <b-form-group>
          <label for="title">
            {{ $t('form-create-item.title') }}*
          </label>
          <b-form-input
            id="title"
            v-model="jobInput.title"
            type="text"
            required
            rules="required"
            :state="getValidationState(validationContext)"
            name=" "
            :placeholder="$t('form-create-item.title')"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row>
        <b-col cols="9">
          <validation-provider #default="validationContext" name=" " rules="required">
            <b-form-group>
              <label class="mt-2" for="responsibilities">
                {{ $t('form-create-item.responsibilities') }}*
              </label>
              <b-form-input
                id="responsibilities"
                v-model="jobInput.responsibilities"
                required
                type="text"
                :placeholder="$t('form-create-item.responsibilities')"
                rules="required"
                :state="getValidationState(validationContext)"
                name=" "
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <validation-provider #default="validationContext" :name="$t('form-create-item.vacants')">
            <b-form-group>
              <label class="mt-2" for="vacants">
                {{ $t('form-create-item.vacants') }}
              </label>
              <b-form-input
                id="vacants"
                v-model="jobInput.vacants"
                type="number"
                :placeholder="$t('form-create-item.vacants')"
                :name="$t('form-create-item.vacants')"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider #default="validationContext" :name="$t('form-create-item.min-years-exp')">
            <b-form-group>
              <label class="mt-2" for="min-exp">
                {{ $t('form-create-item.min-years-exp') }}
              </label>
              <b-form-input
                id="min-exp"
                v-model="jobInput.minYearsExp"
                type="number"
                :placeholder="$t('form-create-item.min-years-exp')"
                :name="$t('form-create-item.min-years-exp')"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider #default="validationContext" :name="$t('form-create-item.max-years-exp')">
            <b-form-group>
              <label class="mt-2" for="max-exp">
                {{ $t('form-create-item.max-years-exp') }}
              </label>
              <b-form-input
                id="max-exp"
                v-model="jobInput.maxYearsExp"
                type="number"
                :placeholder="$t('form-create-item.max-years-exp')"
                :name="$t('form-create-item.max-years-exp')"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <!--  <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label class="mt-2" for="start-date">{{ $t('form-create-item.start-date') }}:</label>
              <b-form-input id="start-date" v-model="jobInput.startDate" type="number" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label class="mt-2" for="end-date">{{ $t('form-create-item.end-date') }}:</label>
              <b-form-input id="end-date" v-model="jobInput.endDate" type="number" />
            </b-form-group>
          </b-col>
        </b-row> -->
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider #default="validationContext" :name="$t('form-create-item.min-brutto-salary')">
            <b-form-group>
              <label class="mt-2" for="min-salary">
                {{ $t('form-create-item.min-brutto-salary') }}
              </label>
              <b-form-input
                id="min-salary"
                v-model="jobInput.minBruttoSalary"
                type="number"
                :placeholder="$t('form-create-item.min-brutto-salary')"
                :name="$t('form-create-item.min-brutto-salary')"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider #default="validationContext" :name="$t('form-create-item.max-brutto-salary')">
            <b-form-group>
              <label class="mt-2" for="max-salary">
                {{ $t('form-create-item.max-brutto-salary') }}
              </label>
              <b-form-input
                id="max-salary"
                v-model="jobInput.maxBruttoSalary"
                :placeholder="$t('form-create-item.max-brutto-salary')"
                type="number"
                :name="$t('form-create-item.max-brutto-salary')"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Currency -->
        <b-col cols="12">
          <validation-provider
            v-if="currencyCode"
            #default="validationContext"
            name=" "
            rules="required"
          >
            <b-form-group :label="$t('backoffice.subscriptions.form.currency')" label-for="role">
              <b-form-select
                id="role"
                v-model="jobInput.currencyCode"
                :options="currencyCode"
                :placeholder="$t('form.type-placeholder')"
                label-type="text"
                @keydown.enter.prevent="onEnter"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <validation-provider #default="validationContext" name=" " rules="required">
        <b-form-group>
          <label class="mt-2" for="name">
            {{ $t('form-create-item.name') }}*
          </label>
          <b-form-input
            id="name"
            v-model="jobInput.name"
            required
            rules="required"
            :state="getValidationState(validationContext)"
            name=" "
            :placeholder="$t('form-create-item.name')"
            type="text"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row>
        <b-col cols="6">
          <validation-provider #default="validationContext" name=" " rules="required">
            <b-form-group>
              <label class="mt-2" for="locality">
                {{ $t('form-create-item.locality') }}*:
              </label>
              <b-form-input
                id="locality"
                v-model="jobInput.locality"
                required
                rules="required"
                :state="getValidationState(validationContext)"
                name=" "
                :placeholder="$t('form-create-item.locality')"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="validationContext" name=" " rules="required">
            <b-form-group>
              <label class="mt-2" for="region">
                {{ $t('form-create-item.region') }}*:
              </label>
              <b-form-input
                id="region"
                v-model="jobInput.region"
                :placeholder="$t('form-create-item.region')"
                rules="required"
                :state="getValidationState(validationContext)"
                name=" "
                required
                type="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      <!-- Place Publish -->
        <b-col cols="12">
          <b-form-group>
            <label for="place">
              {{ $t('events.actions.place-publish') }}
            </label>
            <v-select
              id="place"
              v-model="placePublishOption"
              :clearable="false"
              :reduce="(place) => place.code"
              :options="placePublishOptions"
              label="place"
            />
          </b-form-group>
        </b-col>
        <!-- Join url -->
        <b-col cols="12" v-if="placePublishOption===1">         
          <validation-provider #default="validationContext" name=" ">
          <b-form-group :label="$t('events.actions.link')" label-for="join-link">
            <b-form-input
              id="join-link"
              v-model="jobInput.joinURL"
              type="url"
              autofocus
              :state="getValidationState(validationContext)"
              name=" "
              trim
              :placeholder="$t('events.actions.link-placeholder')"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-form-group>
        <label class="mt-2" for="description">{{ $t('form-create-item.description') }}:</label>
        <quill-editor v-model="jobInput.description" />
      </b-form-group>
      <div class="float-right mb-2">
        <!-- Missing to implement BE -->
        <b-button variant="outline-secondary" type="reset">
          {{ $t('form-create-item.cancel') }}
        </b-button>
        <b-button variant="primary" class="ml-2" type="submit">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import { required } from '@validations';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import Service from '@/config/service-identifiers';
import vSelect from 'vue-select';


export default {
  name: 'JobsListWidget',
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      jobInput: {},
      formError: null,
      results: [],
      required,
      currencyCode: [{ value: null, text: 'Select a Currency...' }],
      placePublishOption: 1,
      placePublishOptions: [
        // { code: 0, place: this.$t('events.event-details.here-place') },
        { code: 1, place: this.$t('events.event-details.external-place') },
      ],
    };
  },

  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  async created() {
    await this.fetchCode();
  },
  methods: {
    async fetchCode() {
      const response = await this.$store.$service[Service.BackendClient].get('currencies', {
        params: {
          communityKey: this.$store.getters.currentCollective.key,
          count: 164,
          forceAPICall: true,
        },
      });
      const currencyArray = response.data.data;
      for (const indexCurrency in currencyArray) {
        const newCurrency = {
          value: currencyArray[indexCurrency].ISO,
          text: `${currencyArray[indexCurrency].ISO} (${currencyArray[indexCurrency].symbol})`,
          ...currencyArray[indexCurrency],
        };
        this.currencyCode.push(newCurrency);
      }
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    // Create item
    async handleCreateItem(bvModalEvt) {
      // Title is required
      if (!this.jobInput.title) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'jobs',
            requestConfig: {
              title: this.jobInput.title,
              responsibilities: this.jobInput.responsibilities,
              vacants: this.jobInput.vacants,
              minBruttoSalary: this.jobInput.minBruttoSalary,
              maxBruttoSalary: this.jobInput.maxBruttoSalary,
              joinURL: this.jobInput.joinURL,
              startDate: this.jobInput.startDate,
              endDate: this.jobInput.endDate,
              minYearsExp: this.jobInput.minYearsExp,
              maxYearsExp: this.jobInput.maxYearsExp,
              description: this.jobInput.description,
              currencyCode: this.jobInput.currencyCode,
              location: {
                name: this.jobInput.name,
                locality: this.jobInput.locality,
                region: this.jobInput.region,
              },
            },
          },
        });
        this.jobInput = {};
        this.formError = null;
        this.$emit('close-modal', false);
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    resetForm() {
      this.$emit('close-modal', false);
    },
  },
};
</script>
