<template>
  <div>
    <b-card>
      <div v-if="!isPreview" class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <!-- <a class=" mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a> -->
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t('jobs.title') }}
          </h3>
        </div>
        <b-button
          v-if="canCreate"
          v-b-modal.modal-create-jobs
          class="float-right mr-1"
          show-add
          variant="outline-primary"
        >
          {{ $t('jobs.create') }}
        </b-button>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block text-primary" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0" class="mx-2">
        <div>
          <b-row class="justify-content-between flex-wrap">
            <!-- Items Loop -->
            <b-col
              v-for="(item, index) in items"
              :key="index"
              no-body
              class="mx-1"
            >
              <b-card class=" p-0 card-standard" style="height: 300px">
                <b-link :to="canView ? { name: 'job', params: { id: item.key } } : null">
                  <b-card-body class="pb-2 d-block">
                    <!-- Job Details -->
                    <b-badge v-if="item.statusID" class="float-right mt-50" :variant="getBadgeVariant(item.statusID)">
                      {{ getBadgeText(item.statusID) }}
                    </b-badge>
                    <h4 class="text-ellipsis-lines-2">
                      {{ item.title }}
                    </h4>
                    <b-card-text v-if="item.location" class="text-secondary mb-0">
                      {{ item.location.locality }}, {{ item.location.region }}
                    </b-card-text>
                    <b-card-text class="text-muted text-ellipsis-lines-2">
                      {{ item.responsibilities }}
                    </b-card-text>
                    <b-card-text v-if="item.minBruttoSalary && item.maxBruttoSalary" class="headline text-muted">
                      {{ $t('jobs.detail.salary') }}: {{ parseFloat(item.minBruttoSalary).toLocaleString(currentLocale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      }) }} - {{ parseFloat(item.maxBruttoSalary).toLocaleString(currentLocale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      }) }} {{ item.currency }}
                    </b-card-text>
                    <b-card-text v-else-if="item.minBruttoSalary && !item.maxBruttoSalary" class="headline text-muted">
                      {{ $t('jobs.detail.salary') }}: {{ parseFloat(item.minBruttoSalary).toLocaleString(currentLocale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      }) }} {{ item.currency }}
                    </b-card-text>
                    <b-card-text v-else-if="!item.minBruttoSalary && item.maxBruttoSalary" class="headline text-muted">
                      {{ $t('jobs.detail.salary') }}: {{ parseFloat(item.maxBruttoSalary).toLocaleString(currentLocale, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                      }) }} {{ item.currency }}
                    </b-card-text>
                    <div style="position: fixed; bottom: 25px">
                      <span class="text-muted">{{  new Date(item.updatedAt).toLocaleString('en-GB').substring(0, 10)}}</span>

                      <!-- Current community -->
                      <b-card-text class="d-flex  align-items-center w-100 mt-50 text-secondary">
                        <div class="d-flex align-items-center">
                          <b-avatar
                            size="26"
                            :src="getAvatar(collective.logoURL)"
                            :alt="collective.name"
                            class="user-avatar mr-1 float-right"
                          />
                          <span class="font-small-4"> {{  translateTranslationTable(
                            $store.state.locale.currentLocale,collective.name) }} </span>
                        </div>
                      </b-card-text>
                    </div>
                  </b-card-body>
                </b-link>
              </b-card>
            </b-col>
          </b-row>

          <!-- Buttons prev and next -->
          <button class="link-previous btn btn-icon btn-primary btn-previous" @click="scrollOnePage(-1)">
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="jobsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: 'items' }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-create-jobs"
      v-model="isContactModalVisible"
      :title="$t('jobs.modal-create-title')"
      centered
      size="lg"
      hide-footer
    >
      <job-create-form @close-modal="isContactModalVisible = false" />
    </b-modal>

    <!-- Create JOB -->
  </div>
</template>

<script>
import JobsPlaceholder from '@/assets/images/placeholders/light/jobs.svg';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import { quillEditor } from 'vue-quill-editor';
import { required } from '@validations';
import { checkPermissions } from '@/@core/utils/roles-utils';
import JobCreateForm from './JobCreateForm.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'JobsList',
  components: {
    JobCreateForm,
    quillEditor,
  },
  props: {
    jobs: {
      type: Array,
      required: false
    },
    community: {
      type: Object,
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      jobInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
      required,
      isContactModalVisible: false,
      items: []
    };
  },
  computed: {
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === 20)
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    itemsData() {
      return this.$store.getters.jobs;
    },
    unpaginatedItems() {
      return this.itemsData.unpaginated;
    },
    collective() {
      if(this.community){
        return this.community;
      }
      return this.$store.getters.currentCollective;
    },
    jobsPlaceholder() {
      return JobsPlaceholder;
    },
    canCreate() {
      return checkPermissions('create', 'job', this.loggedMemberRoles, this.collective);
    },
    canView() {
      return checkPermissions('view', 'job', this.loggedMemberRoles, this.collective);
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    jobs() {
      this.items = this.jobs;
    }
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    const shouldFetch = !this.jobs?.length && !this.isPreview;
    console.log('shouldFetch', shouldFetch)
    if(shouldFetch){
      await this.fetchData();
    } else {
      this.items = this.jobs;
    }
    this.isLoading = false;
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    getAvatar(url) {
      return getImageResource(url);
    },
    // Get jobs
    async fetchData() {
      // console.log('Fetching jobs...');
      this.isLoading = true;
      const result = await this.$store.dispatch('getItems', {
        itemType: 'jobs',
        page: this.lastLoadedPage,
        forceAPICall: true,
        communitySlug: this.community ? this.community.slug : null,
        requestConfig: {
          count: 100,
          orderByDate: -1,
          classifiers: this.finalSelectClassifier,
          customFields:
            this.customFieldToSend != null && Object.keys(this.customFieldToSend).length > 0
              ? this.customFieldToSend
              : null,
        },
      });
      if(result && result.total){
        this.items = result.data;
      }
      this.updateBreadcrumbs();
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.unpaginatedItems.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    getBadgeText(status) {
      if (!status || status === 42678) {
        return 'Abierto';
      }
      if (!status || status === 42681) {
        return;
        ('Abierto');
      }
      if (status == 42659 || status === 42736) {
        return 'Resuelto';
      }
    },
    getBadgeVariant(status) {
      if (!status || status === 42658) {
        return 'warning';
      }
      if (status == 42659 || status === 42678) {
        return 'success';
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            this.appTitle ||
            this.$t('jobs.title'),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    translateTranslationTable,
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
/* @media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
} */
</style>
